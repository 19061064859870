/**视频详情start**/
.courseTitleStyle{
    font-size: 19px;
    font-weight:bold;
    margin: 15px;
    color: #424242;
}
.describe{
    font-size: 14px;
    line-height: 22px;
    padding: 0 9px 18px 14px;
}
/**视频详情end**/

/**教师详情start**/
.course-intro{
    display: flex;
    padding: 10px 30px;
}

.intro-img{
    margin-right: 30px;
}

.intro-img img{
    border-radius: 30px;
    width:80px;
    height:80px;
}

.intro-content{
    width: 100%;
}

.intro-content p{
    margin: 0;
    font-size: 14px;
}

.intro-content .intro-title{
    font-size: 15px;
    color: #f42429;
}

.intro-content .intro-subtitle{
    margin-top: 6px;
}

.intro-content .intro-msg{
    margin: 6px 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
/**教师详情end**/
.my-list .spe .am-list-extra {
    flex-basis: initial;
}

/**进度条式样start**/
.show-info {
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.show-info .progress {
    margin-right: 5px;
    width: 80%;
}
/**进度条式样end**/
/**radioButton start**/
.my-radio .am-radio {
    padding: 1px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 5px;
}
/**radioButton end**/
/*基本信息页面头像设置start*/
.userInfo-intro{
    display: flex;
    padding: 10px 10px;
}

.userInfo-img{
    margin-right: 20px;
}

.userInfo-img img{
    border-radius: 40px;
    width:60px;
    height:60px;
}
.userInfo-content{
    width: 100%;
}

.userInfo-content .userInfo-name{
    font-size: 24px;
    margin-top: 15px;
    /*color: #f42429;*/
}
.userInfo-edit{
    margin: 18px 5px;
    text-align: right;
    font-size: 15px;
    display: block;
    width: 60px;
    height: 20px;
    color: #b1b1b1;
}
/*基本信息页面头像设置end*/
.article-info-box{
    padding: 14px;
    position: relative;
    border-bottom: 1.2px dashed #DDDDDD;
}
.article-info-box .article-bar-top{
    color: #858585;
    padding-top: 10px;
}

.article-info-box .title-article{
    font-size: 18px;
}

.article-content{
    padding: 14px;
}
.article-content .img-avatar-box{
    float: left;
    margin-right: 10px;
}
.article-content .answer{
    color: #999;
    margin-top: 8px;
    padding-left: 8px;
}
.article-content .answer .name{
    color: #999;
    padding-right: 8px;
}
.answer-content{
    margin-top: 10px;
    margin-left: 10px;
}

/******ios扫码提示*******/
.iosTipsLayer{
    margin:35px
}
.iosTipsLayer .tipsError{
    margin-bottom:40px;
    font-size: 16px;
    font-weight:bold;
    color: #848484;
}
.iosTipsLayer .operation{
    margin-bottom:16px;
    font-size: 15px;
    font-weight:bold;
    color: #848484;
}
.iosTips{
    font-size: 15px;
    color: #848484;
}
.tipsImg{
    width: 35px;
    vertical-align: middle;
}
.homeTitleName{
    margin-bottom:10px;
    color: #424242;
    font-size: 16px;
}

.certificateLogo{
    display: block;
    width: 120px;
    border-radius: 50%;
    margin: 30px auto;
}

.certificateImg{
    display: block;
    width: 90%;
    margin: 30px auto;
}