.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.oneTextOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*主title样式*/
.userTitleStyle {
  font-size: 16px;
  margin: 7px;
  color: #626262;
}

/*虚线分割*/
.hr0 {
  height: 1.5px;
  border: none;
  border-top: 1px dashed #c0c0c0;
}

/*实线分割*/
.hr1 {
  height: 1.5px;
  border: none;
  border-top: 1px solid #c0c0c0;
  padding-bottom: 8px;
}

/**加载等待start**/
.loading-example {
  display: flex;
  justify-content: flex-start;
}

.align {
  display: flex;
  flex-direction: column;
}

.sub-title {
  color: #888;
  font-size: 14px;
  padding: 30px 0 18px 0;
}

/**加载等待end**/
.show-info {
  margin-top: 18px;
  display: flex;
  align-items: center;
}

.my-list .spe .am-list-extra {
  flex-basis: initial;
}

.pagination-container {
  margin: 0 15px;
}

.custom-pagination-with-icon .am-pagination-wrap-btn-prev .am-button-inline {
  padding-left: 0;
  padding-right: 10px;
}

.custom-pagination-with-icon .am-pagination-wrap-btn-next .am-button-inline {
  padding-left: 10px;
  padding-right: 0;
}

.arrow-align {
  display: flex;
  align-items: center;
}

.sub-title {
  color: #888;
  font-size: 14px;
  padding: 30px 0 18px 0;
}

.newsContent p, .newsContent p span {
  font-size: 16px !important;
}

.newsContent img.image-wrap {
  width: 100%;
}

.likeAnimation {
  animation: clickLike 1.2s ease-in-out;
}

@keyframes clickLike {
  0% {
    transform: rotate(5deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  40% {
    transform: scale(3);
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.comment-item, .comment-item div {
  overflow: inherit !important;
}
